// Some libraries expect global to be defined
// Webpack used to do this for us, but vite does not
window.global ||= window;

export const LoadClientEnv = () => {
  window.RELEASE_VERSION = getReleaseVersion();
  window.DD_APPLICATION_ID = 'efc32150-7dd1-408b-a2e2-8d3f07d96001';
  window.DD_CLIENT_TOKEN = 'pubc9f3746b568feae4c50c0ebc9d14e695';
  window.AMPLITUDE_HOST = 'amplitude-api.f4s.com';
  window.SEGMENT_HOST = 'segment-cdn.f4s.com';

  // Production
  if (window.location.host === 'app.fingerprintforsuccess.com') {
    window.SENTRY_DSN_CLIENT =
      'https://490848ee138a451bbd0f2be0b24ecc45@sentry.io/247715';
    window.DD_ENV = getDataDogEnv();
    window.SEGMENT_ID = 'iA43r4LMhcmaHZ13Li2bbeOr7OUZk7J5';
    window.GTM_ID = 'GTM-MJPGXSRN';
    window.CLARITY_ID = 'iifbzgg7ca';
  }

  // Development/Staging
  else {
    window.SENTRY_DSN_CLIENT =
      'https://940014e98a3446869ab02e00704c3787@o976877.ingest.sentry.io/5933280';
    window.DD_ENV = getDataDogEnv();
    window.SEGMENT_ID = 'Dekuc8Jx9CMJilkKXiiIT8GeU8T7DxK7';
    window.GTM_ID = 'G-88L6CEG60G'; // Not a GTM ID, but it's fine
  }
};

/**
 * Gets the data dog environment key for the current host
 * @example - Returns "release-candidate" on release-candidate.f4s.com
 * @example - Returns "development" on localhost
 * */
function getDataDogEnv() {
  if (window.location.host === 'app.fingerprintforsuccess.com') return 'production';
  const envMatch = window.location.hostname.match(/(?<env>.+)\.f4s\.com$/)?.groups?.env;
  return envMatch ?? 'development';
}

function getReleaseVersion() {
  return (
    import.meta.env.VITE_RELEASE_VERSION ??
    (document.querySelector('meta[name="release-version"]') as HTMLMetaElement)
      ?.content ??
    'development'
  );
}
