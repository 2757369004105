import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { errorHandler } from '@/lib/utils/error-handler';

import * as API from '../apis';

export function useSignUpMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['user', 'signupV2'],
    mutationFn: ({
      emailAddress,
      signupFlow,
      isOpenToMarketingEmails,
      ...rest
    }: Parameters<typeof API.user.signupV2>[0] & { signupFlow: string }) =>
      API.user.signupV2({
        emailAddress,
        ...rest,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        properties: { signupFlow, ...rest.properties },
        isOpenToMarketingEmails,
      }),
    onSuccess: () => {
      // Invalidate all queries on signup/login
      queryClient.invalidateQueries();
    },
    onError: (err) => errorHandler(err),
  });
}

export function useEmbeddedSignUpMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['user', 'signupV2'],
    mutationFn: ({
      emailAddress,
      signupFlow,
      isOpenToMarketingEmails,
    }: Parameters<typeof API.user.embeddedSignUp>[0] & { signupFlow: string }) =>
      API.user.embeddedSignUp({
        emailAddress,
        properties: { signupFlow },
        isOpenToMarketingEmails,
      }),
    onSuccess: () => {
      // Invalidate all queries on signup/login
      queryClient.invalidateQueries();
    },
    onError: (err) => errorHandler(err),
  });
}

export function useUpdateProfileMutation() {
  const queryClient = useQueryClient();
  return useMutation(API.user.updateProfile, {
    mutationKey: ['user', 'updateProfile'],
    onSuccess: () => {
      queryClient.invalidateQueries(['appSettings.get']);
      queryClient.invalidateQueries(['business-email']);
    },
    onError: (err) => {
      errorHandler(err);
    },
  });
}

export function useSetGoalsMutation() {
  const queryClient = useQueryClient();
  return useMutation(API.user.setGoals, {
    mutationKey: ['user', 'setGoals'],
    onSuccess: () => {
      queryClient.invalidateQueries(['coaching-goals']);
    },
    onError: (err) => {
      errorHandler(err);
    },
  });
}

export function useBusinessEmail(id: number | null, enabled: boolean = false) {
  return useQuery({
    queryKey: ['user', id, 'business-email'],
    queryFn: () => API.user.getBusinessEmail().then((res) => res.data),
    placeholderData: '',
    enabled,
  });
}

export const useSetUserProperty = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { property: string; value: any }) =>
      API.user.setProperty(data.property, data.value),
    {
      onSuccess: () => queryClient.invalidateQueries(['appSettings.get']),
      onError: (error) => errorHandler(error),
    },
  );
};

export function useGetReducedUserById(userId: number, enabled: boolean = false) {
  return useQuery({
    queryKey: ['user.getPublicDataFromId', userId],
    queryFn: () => API.user.getReducedUserById(userId).then((res) => res.data),
    enabled,
  });
}

export function useGetCompanies(slug?: string, enabled: boolean = false) {
  return useQuery({
    queryKey: ['companies', slug],
    queryFn: async (): Promise<{ companyName: string }[]> =>
      API.user.getCompanies(slug).then((res) => res.data),
    enabled,
  });
}
