import clsx from 'clsx';
import { useState, type PropsWithChildren } from 'react';

import { Loader } from '../loader/loader';
import {
  type ButtonColor,
  type ButtonSize,
  type ButtonVariant,
  type CoreButtonProps,
} from './types';

import styles from './button.module.scss';

const getBgAndBorderColor = (
  variant: ButtonVariant,
  color: ButtonColor,
  isDisabled: boolean,
) => {
  if (variant === 'primary') {
    if (isDisabled) return `gray-300`;
    if (['brand', 'black', 'red'].includes(color)) {
      return color !== 'black' ? `${color}-500` : 'gray-800';
    }
    return `${color}-base`;
  }

  if (variant === 'secondary') {
    return isDisabled || color === 'black' ? `gray-100` : `${color}-50`;
  }

  return 'transparent';
};

const getBgColorForWhiteVariant = (
  variant: ButtonVariant,
  color: ButtonColor,
  isDisabled: boolean,
) => {
  if (variant === 'white' && ['brand', 'black'].includes(color)) {
    return !isDisabled ? 'white' : `gray-50`;
  }
  return !isDisabled ? 'white' : `${color}-50`;
};

const getBorderColorForWhiteVariant = (variant: ButtonVariant, color: ButtonColor) => {
  if (variant === 'white' && ['brand', 'black'].includes(color)) {
    return `gray-300`;
  }
  return `${color}-300`;
};

const getHoverBgAndBorderColor = (
  variant: ButtonVariant,
  color: ButtonColor,
  isIconOnly: boolean,
) => {
  if (variant === 'primary') {
    return color !== 'black' ? `${color}-600` : 'gray-900';
  }
  if (variant === 'secondary') {
    return color !== 'black' ? `${color}-100` : 'gray-200';
  }
  if (variant === 'link' && !isIconOnly) {
    return color !== 'brand' && color !== 'black' ? `${color}-100` : 'gray-100';
  }
  return 'transparent';
};

const getHoverBgColorForWhiteVariant = (variant: ButtonVariant, color: ButtonColor) =>
  variant === 'white' && ['brand', 'black'].includes(color) ? `gray-50` : `${color}-50`;

const getHoverBorderColorForWhiteVariant = (
  variant: ButtonVariant,
  color: ButtonColor,
) =>
  variant === 'white' && ['brand', 'black'].includes(color) ? `gray-300` : `${color}-300`;

const getTextColor = (variant: ButtonVariant, color: string, isDisabled: boolean) => {
  if (variant === 'primary') {
    return 'white';
  }
  if (variant === 'secondary' && color !== 'black') {
    return isDisabled ? 'gray-400' : `${color}-600`;
  }
  if (['white', 'link'].includes(variant) && color === 'brand') {
    return isDisabled ? 'gray-400' : `gray-700`;
  }
  if (color === 'black') {
    return isDisabled ? 'gray-400' : `gray-900`;
  }
  return isDisabled ? `${color}-400` : `${color}-700`;
};

const getSvgColor = (variant: ButtonVariant, color: string, isDisabled: boolean) => {
  if (variant === 'primary') {
    return 'white';
  }
  if (variant === 'secondary' && isDisabled) {
    return 'gray-300';
  }
  if (['white', 'link'].includes(variant) && color === 'brand') {
    return isDisabled ? 'gray-300' : `gray-500`;
  }
  if (color === 'black') {
    return isDisabled ? 'gray-400' : `gray-900`;
  }
  return isDisabled ? `${color}-300` : `${color}-500`;
};

const getGap = (size: ButtonSize) => {
  if (['xs', 'sm', 'base'].includes(size)) {
    return 'gap-2';
  }
  return 'gap-3';
};

const getFrontSize = (size: ButtonSize) => {
  if (size === 'xs') {
    return 'text-xs';
  }
  if (['sm', 'base'].includes(size)) {
    return 'text-sm';
  }
  return 'text-base';
};

const getPaddingWhenHavingText = (size: ButtonSize) => {
  if (size === 'xs') {
    return ['py-1.5', 'px-2.5'];
  }
  if (size === 'sm') {
    return ['py-2', 'px-3'];
  }
  if (['base', 'lg'].includes(size)) {
    return ['py-2', 'px-4'];
  }
  return ['py-3', 'px-6'];
};

const getPaddingWhenIconOnly = (size: ButtonSize) => {
  if (size === 'xs') {
    return ['p-1'];
  }
  if (size === 'sm') {
    return ['p-1.5'];
  }
  if (['base', 'lg'].includes(size)) {
    return ['p-2'];
  }
  return ['p-3'];
};

export const ButtonWithChildren = ({
  variant,
  color = 'brand',
  size = 'base',
  isSubmitBtn = false,
  disabled = false,
  isFullWidth = false,
  isLoading = false,
  isIconOnly = false,
  isSolidIcon = false,
  isColumnDirection = false,
  onClick,
  children,
  isThemed = false,
}: PropsWithChildren<CoreButtonProps>): React.ReactElement => {
  const [hover, setHover] = useState(false);
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setHover(false);
    if (typeof onClick !== 'function') return;
    e.stopPropagation();
    onClick(e);
  };
  const bgColor =
    variant === 'white'
      ? getBgColorForWhiteVariant(variant, color, disabled)
      : getBgAndBorderColor(variant, color, disabled);
  const borderColor =
    variant === 'white'
      ? getBorderColorForWhiteVariant(variant, color)
      : getBgAndBorderColor(variant, color, disabled);
  const hoverBgColor =
    variant === 'white'
      ? getHoverBgColorForWhiteVariant(variant, color)
      : getHoverBgAndBorderColor(variant, color, isIconOnly);
  const hoverBorderColor =
    variant === 'white'
      ? getHoverBorderColorForWhiteVariant(variant, color)
      : getHoverBgAndBorderColor(variant, color, isIconOnly);
  const textColor = getTextColor(variant, color, disabled);
  const svgColor = getSvgColor(variant, color, disabled);
  const gap = !isIconOnly ? getGap(size) : null;
  const fontSize = !isIconOnly ? getFrontSize(size) : null;
  const padding = isIconOnly
    ? getPaddingWhenIconOnly(size)
    : getPaddingWhenHavingText(size);

  return (
    <button
      type={isSubmitBtn ? 'submit' : 'button'}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      className={clsx(
        styles.button,
        styles[variant],
        styles[size],
        styles[color],
        `bg-${bgColor}`,
        `border-${borderColor}`,
        `text-${textColor}`,
        `stroke-${isSolidIcon ? 'none' : svgColor}`,
        `fill-${isSolidIcon ? svgColor : 'none'}`,
        gap,
        fontSize,
        'font-medium',
        ...padding,
        'outline-none',
        isLoading && 'text-opacity-0',
        {
          [styles.disabled]: disabled,
          [styles.iconOnly]: isIconOnly,
          [styles.fullWidth]: isFullWidth,
          [styles.loadingIcon]: isLoading,
          [styles.solidIcon]: isSolidIcon,
          'flex-col': isColumnDirection,
          [`bg-${hoverBgColor}`]: hover,
          [`border-${hoverBorderColor}`]: hover,

          // TODO: Add general theme support
          'bg-primary': isThemed && variant === 'primary' && !disabled,
          'border-primary': isThemed && variant === 'primary' && !disabled,
          'bg-primary-600': isThemed && variant === 'primary' && !disabled && hover,
        },
      )}
      disabled={disabled}
      onClick={onButtonClick}
    >
      {children}
      {isLoading && (
        <Loader
          size="sm"
          transparent
          absolute
          strokeColor={variant === 'white' ? `stroke-${svgColor}` : undefined}
        />
      )}
    </button>
  );
};
