import AppText from '@f4s/i18n/en.json';

export const NAV_HEIGHT = 112; // 60 + 52
export const LOGO = '/assets/f4s_logo_icon_only.svg';
export const PROGRAM_ACTIVITY_LIMIT = 2;
export const DEFAULT_PAGE_APP = '/';
export const DEFAULT_TEAMS_INSIGHTS_PAGE = '/teams/insights';
export const DEFAULT_ROOT_PAGE = '/';
export const CARDS_DATE_FORMAT = 'd MMMM, y';
export const REDESIGN_BANNER_WIDTH = 1040;

export const EXAMPLE_CSV_FILE = 'https://cdn.f4s.com/Bulk+Upload+-+CSV+Example.csv';
export const EMOJI_DIR = 'https://cdn.f4s.com/emoji/';
export const EMOJI_EXT = '.svg';

export const DEFAULT_AVATAR = {
  GROUP: '/assets/teams-placeholder.png',
  ORG: '/assets/teams-placeholder.png',
  USER: '/assets/default-avatar.png',
  LOCKED: '/assets/locked-avatar.png',
};

export const SETTINGS_TABS_USER = {
  settings: 'Settings',
  billing: 'Billing',
  permissions: 'Permissions',
  preferences: 'Preferences',
  integrations: 'Integrations',
  talentHub: 'Talent Hub',
  language: 'Language',
};

export const MEMBER_ROLES = {
  isMember: {
    title: 'Member',
    description: 'A member is someone whose results are included in the team.',
    weight: 4,
  },
  isViewer: {
    title: 'Viewer',
    description: 'A viewer is someone who can view the results of the team.',
    weight: 2,
  },
  isAdmin: {
    title: 'Team Admin',
    description:
      'An admin can view the results of the team and add / remove members to and from the team.',
    weight: 1,
  },
};

export const MEMBER_ROLES_SUMMARY = {
  1: 'This person will not have their results included in the team and will be able to view the results of other members in the team and also edit the team.', // admin
  2: 'This person will not have their results included in the team and will be able to view the results of other members in the team.', // viewer
  3: 'This person will not have their results included in the team and will be able to view the results of other members of the team and also edit the team.', // admin + viewer
  4: 'This person will have their results included in the team but will not be able to view the results of the team.', // member
  5: 'This person will have their results included in the team and will be able to view the results of the team and edit the team.', // member + admin
  6: 'This person will have their results included in the team and will be able to view the results of other members of the team.', // member + viewer
  7: 'This person will have their results included in the team and will be able to view the results of the team and edit the team.', // member + viewer + admin
};

export const ORG_MEMBER_ROLES = {
  isMember: {
    title: 'Member',
    description: 'Is a member of ${orgName} in F4S',
    weight: 4,
    condition: ({ isMember, isAdmin }) => isMember && !isAdmin,
  },
  isAdmin: {
    title: 'Org Admin',
    description:
      "An admin can view ${orgName} aggregate data and can change and manage ${orgName}'s overall settings and members.",
    weight: 1,
  },
};

export const ORG_MEMBER_ROLES_SUMMARY = {};

export const DEFAULT_MEMBER_ROLE = {
  isMember: true,
  isGroupMember: true,
  isViewer: false,
  isAdmin: false,
};

export const DEFAULT_ORG_MEMBER_ROLE = {
  isMember: true,
  isAdmin: false,
};

export const DEFAULT_MEOVERTIME_ROLE = {
  isMember: true,
  isViewer: false,
  isAdmin: false,
};

export const DEFAULT_SEARCH_FILTER = [{ key: 'invitableUsers' }];

const PUBLIC_API_URL = 'https://app.fingerprintforsuccess.com/public/v1';
export const APP_ACCESS_INFO = [
  { title: AppText.developer_overview_section_access_url, value: PUBLIC_API_URL },
  { title: AppText.developer_overview_section_org_id, value: '' },
  { title: AppText.developer_overview_section_access_key, value: '' },
];

export const LIST_DEFAULT_SIZE = {
  ORGANIZATION: 3,
  GROUP: 3,
  CONNECTION: 10,
  INVITATION: 3,
};

export const MAX_FREE_MEMBERS = 5;

export const QUESTIONS_LABEL_TRANSLATE = {
  'en-us': ['Most like you', 'Least like you', 'Back', 'Next', 'Finish', 'Language'],
  'ar-ae': ['معظم مثلك', 'على الأقل مثلك', 'عودة', 'التالى', 'إنهاء', 'لغة'], // reverse order due to the language
  'zh-cn': ['最符合你', '最不符合你', '返回', '下一个', '完成', '语言'],
  'cs-cz': [
    'Nejvíc se ti líbí',
    'Nejméně jako ty',
    'Zadní',
    'další',
    'Dokončit',
    'Jazyk',
  ],
  'da-dk': ['Mest ligesom dig', 'Mindst som dig', 'Tilbage', 'Næste', 'Afslut', 'Sprog'],
  'he-il': ['הכי כמוך', 'פחות כמוך', 'חזור', 'הַבָּא', 'סיים', 'שפה'], // reverse order due to the language
  'id-id': [
    'Paling seperti kamu',
    'Setidaknya sepertimu',
    'Kembali',
    'Lanjut',
    'Selesai',
    'Bahasa',
  ],
  'it-it': ['Più come te', 'Almeno come te', 'Indietro', 'Avanti', 'Fine', 'linguaggio'],
  'ja-jp': [
    'あなたのような',
    'あなたのように少なくとも',
    'バック',
    '次',
    '仕上げ',
    '言語',
  ],
  'ko-kr': ['당신처럼', '당신처럼', '뒤', '다음', '끝', '언어'],
  'ms-my': [
    'Paling suka anda',
    'Kurang seperti anda',
    'Belakang',
    'Seterusnya',
    'Selesai',
    'Bahasa',
  ],
  'nl-nl': [
    'Het meest op jou',
    'Het minst zoals jij',
    'Terug',
    'De volgende',
    'Af hebben',
    'Taal',
  ],
  'es-ar': [
    'se parece mas a ti',
    'se parece menos a ti',
    'anterior',
    'siguiente',
    'Terminar',
    'Idioma',
  ],
  'sv-se': ['Gillar dig', 'Minst som du', 'Tillbaka', 'Nästa', 'Avsluta', 'Språk'],
  'th-th': ['ใช่คุณมากที่สุด', 'ไม่ค่อยเหมือนคุณ', 'กลับ', 'ต่อไป', 'เสร็จสิ้น', 'ภาษา'],
  'fr-fr': [
    'La plupart comme toi',
    'Le moins comme toi',
    'Arrière',
    'Prochain',
    'terminer',
    'Langue',
  ],
  'de-de': [
    'Am liebsten wie du',
    'Am wenigsten wie du',
    'Zurück',
    'Nächster',
    'Fertig',
    'Sprache',
  ],
  'pl-pl': [
    'Najbardziej jak ty',
    'Najmniej jak ty',
    'Plecy',
    'Kolejny',
    'koniec',
    'Język',
  ],
  'pt-pt': [
    'Mais parecido com você',
    'Menos parecido com você',
    'Costas',
    'Próxima',
    'Terminar',
    'Língua',
  ],
  'ro-ro': [
    'Cel mai mult ca tine',
    'Cel puțin ca tine',
    'Înapoi',
    'Următor',
    'finalizarea',
    'Limba',
  ],
  'ru-ru': [
    'Больше всего как ты',
    'Меньше всего как ты',
    'Назад',
    'следующий',
    'финиш',
    'Язык',
  ],
  'tr-tr': ['En çok senin gibi', 'En az senin gibi', 'Geri', 'Sonraki', 'Bitiş', 'Dil'],
};

export const MARLEE_PROGRAM_INIT_STATE = 'init';
export const MARLEE_PROGRAM_ADD_TO_QUEUE_STATE = 'addToQueue';

export const MARLEE_CURRENT_PROGRAM_STATES = {
  disabled: {},
  init: {
    label: 'Start ${program}',
    actionLabel: 'Start Program',
    actionState: 'sessionReady',
    launchChat: true,
  },
  addToQueue: {
    label: 'Add to queue ',
    actionLabel: 'Add to queue',
    actionState: 'addToQueue',
    launchChat: false,
  },
  started: {
    label: 'Complete Current Session',
    subLabel: '${sessionName}',
    actionLabel: 'Pause',
    actionState: 'startedPaused',
    confirmText: 'Are you sure you want to pause ${programname} coaching program?',
    canCancel: true,
    launchChat: false,
  },
  complete: {
    label: 'Program Achieved! 🌟',
    launchChat: false,
  },
  scheduled: {
    label: 'Next Session',
    subLabel: '${sessionName} - ${day}',
    canCancel: true,
    launchChat: false,
  },
  ready: {
    label: 'Session Ready',
    subLabel: '${sessionName}',
    actionLabel: 'Start Session',
    actionState: 'started',
    canCancel: true,
    launchChat: true,
  },
};

export const SETTING_ORG_MEMBER_FILTER = [
  { value: 'all', display: 'All' },
  { value: 'pending', display: 'Pending' },
  { value: 'joined', display: 'Joined' },
  { value: 'request', display: 'Request' },
  { value: 'removed', display: 'Removed' },
  { value: 'declined', display: 'Declined' },
  { value: 'questionnaire', display: 'Pending Assessment' },
];

export const ORG_MEMBER_FILTER_STATUS_MAPPING = {
  pending: ['pending', 'invited', 'inviting'],
  joined: ['approved'],
  declined: ['declined'],
  request: ['request'],
  removed: ['removed'],
};

export const ORG_MEMBER_STATUS_FILTER_MAPPING = {
  pending: 'pending',
  invited: 'pending',
  inviting: 'pending',
  approved: 'joined',
  declined: 'declined',
  request: 'request',
};

export const URL_PERSISTANT_PARAMS = [
  'organizationId',
  'groupId',
  'userId',
  'id',
  'zoom',
];

export const NOTIFICATION_ORG_STATUS_MAPPING = {
  org_accept: 'Joined',
  org_approve: 'Approved',
  org_request: 'Requested',
  org_waiting: 'Waiting',
  org_removed: 'Removed',
};

export const NOTIFICATION_ALERT_MESSAGE_MAPPING = {
  Removed: 'You have been removed from this team',
  Disconnected: 'You have disconnected with this user',
  Waiting: 'The user not yet accept invite',
};

export const NOTIFICATION_SEGMENT_SOURCE_MAPPING = {
  connect_invite: 'connection',
  group_invite: 'group',
  org_invite: 'organization',
};

export const PAUSE_POINTS = {
  25: '/assets/_pause-point-25.svg',
  50: '/assets/_pause-point-50.svg',
  75: '/assets/_pause-point-75.svg',
  100: '/assets/_pause-point-100.svg',
};

export const ORG_LOCAL_STORAGE_KEY = 'orgData';
export const ONBOARDING_LOCAL_STORAGE_KEY = 'onboarding';
export const INVITE_LOCAL_STORAGE_KEY = 'invitation';
export const DEFAULT_CLIENT_DOMAIN = 'canva.com';
export const SESSION_STORAGE_REFERRAL_CODE = 'referralCode';
export const SESSION_STORAGE_SIGNUP_PROGRAM = 'signupProgram';
export const SESSION_STORAGE_INTEGRATION_CANDIDATE_TOKEN = 'integrationCandidateToken';
export const SESSION_STORAGE_SIGNUP_GOAL = 'signupGoal';
export const BLISSPOT_LINKED_PROGRAM_ID = 'wellbeing';
export const SESSION_STORAGE_CAMPAIGN_CODE = 'campaignCode';
export const SESSION_STORAGE_REFERRER_TOKEN = 'referrerToken';

export const SIGNUP_LOGGEDIN_STEPS = [
  'refer',
  'refer-emails',
  'questionnaire',
  'recommendations',
  'email-error',
];

export type LanguageCode = keyof typeof QUESTIONS_LABEL_TRANSLATE;
