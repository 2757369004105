import {
  type AssessmentBlock,
  type MiniAssessmentBlock,
  type MiniAssessmentGeneralInfo,
  type MiniAssessmentQuestions,
  type MiniAssessmentResult,
  type QuestionOptions,
} from '@f4s/types';
import { type QuestionAnswer } from '@f4s/types/common/questionnaire';

import { type QUESTIONS_LABEL_TRANSLATE } from 'Constants';

import { httpAgent as http } from './agents/http';

type LanguageCode = keyof typeof QUESTIONS_LABEL_TRANSLATE;
export const getAllQuestions = (slug: AssessmentBlock, language: LanguageCode) =>
  http().get<MiniAssessmentQuestions>(`/api/v3/mini-assessments/${slug}/${language}`);

export const getTotalSavedQuestionsNumber = (slug: AssessmentBlock) =>
  http().get(`/api/v3/mini-assessments/saved-total/${slug}`);

export const getMiniAssessmentGeneralInfo = (slug: AssessmentBlock) =>
  http().get<MiniAssessmentGeneralInfo>(`/api/v3/mini-assessments/general-info/${slug}`);

export const createMiniAssessment = (slug: string, context: string) =>
  http().post(`/api/v3/mini-assessments/${slug}`, { context });

export const submitMiniAssessment = (slug: string, source: string) =>
  http().post(`/api/v3/mini-assessments/${slug}/finalize`, { source });

export const saveQuestionOrder = (
  slug: string,
  order: {
    question: number;
    answers: QuestionOptions[];
    questionIndex: number;
  },
  context: string,
) =>
  http().post<{ status: string; message: string }>(
    `/api/v3/mini-assessments/${slug}/save-order`,
    { ...order, context },
  );

export const getMiniAssessmentForPublicUser = (
  slug: AssessmentBlock,
  language: LanguageCode,
) => http().get(`/api/v3/public/mini-assessments/all/${slug}/${language}`);

export const createAndFinalizeMiniAssessment = (data: {
  answers: QuestionAnswer[];
  blockSlug: string;
}) => http().post(`/api/v3/mini-assessments/save-and-finalize`, data);

export const getMiniAssessmentResult = (slug: string) =>
  http().get<MiniAssessmentResult[]>(`/api/v3/mini-assessments/result/${slug}`);

export const getNextMiniAssessment = () =>
  http().get<MiniAssessmentBlock | null>(`/api/v3/mini-assessments/next`);
