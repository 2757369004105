import type { ValueIteratee } from 'lodash';
import { camelCase, countBy, isEmpty, maxBy, partition, upperFirst } from 'lodash-es';
import debounceOriginal from 'lodash-es/debounce';

import type { NonNullableProperty } from 'Types';

/** Moves a value to an index in an array, shifting values in-between */
export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const SEARCH_DEBOUNCE_TIME = 300;

/**
 * Wrapper function around lodash's debounce
 * @see https://lodash.com/docs#debounce
 */
export const debounce = <T extends (...args: any[]) => void>(func: T, time?: number) =>
  debounceOriginal(func, time || SEARCH_DEBOUNCE_TIME, { trailing: true });

export const emptyFunc = () => {};

/** Redirects to internal page after given delay */
export const redirectAsync = (path: string, delay = 1500) =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      window.location.href = path;
      resolve();
    }, delay);
  });

/** Converts string to lowercase & strips chars other than a-z0-9_ */
export const lowerAndClean = (string: string) => {
  if (typeof string !== 'string') return string;
  return string.toLowerCase().replaceAll(/\W+/g, '');
};

/** Creates a new array with the specified value at the front */
export const moveToFront = <T>(arr: T[], predicate: ValueIteratee<T>): T[] =>
  partition(arr, predicate).flat();

/**
 * Curried function to detect if a property exists on an object \
 * Includes type guard to correctly remove nullable union from property
 */
export const propertyExists =
  <T extends Record<string, unknown>, P extends keyof T>(property: P) =>
  (object: T): object is NonNullableProperty<T, P> =>
    object[property] != null; // not-nullish - null or undefined

/**
 * Inverse of isEmpty with a non-nullable type guard to exclude undefined
 * Used to assist removal of isEmpty for stricter type checking
 * */
export const isNotEmpty = <T>(value: T): value is NonNullable<T> => !isEmpty(value);

/**
 * Returns the most frequent value in an array
 * Only supports values of type string | number
 * */
export const mostFrequentValue = <T extends PropertyKey>(items: T[]): T => {
  const itemsWithCount = Object.entries(countBy(items));
  return maxBy(itemsWithCount, 1)?.[0] as T;
};

/** Converts a string to PascalCase */
export const pascalCase = (str: string) => upperFirst(camelCase(str));

export {
  concat,
  orderBy,
  compact,
  uniq,
  uniqBy,
  keyBy,
  sum,
  groupBy,
  capitalize,
  get,
  trim,
  keys,
  head,
  last,
  /** @deprecated Prefer using stricter checks for type guards */
  isEmpty,
  pick,
  pickBy,
  uniqueId,
  cloneDeep,
  upperFirst,
  size,
  startCase,
  times,
  merge,
  range,
  clamp,
  replace,
  kebabCase,
  camelCase,
  inRange,
  isNil,
  isNumber,
  shuffle,
  isBoolean,
} from 'lodash-es';
