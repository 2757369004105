import type { setExtras } from '@sentry/react';

import { Sentry } from './sentry';

type Extras = Parameters<typeof setExtras>[0];

export const logError = (
  error: unknown,
  { tag = '', ...extras }: { tag?: string } & Extras = {},
) => {
  Sentry.captureException(error, (scope) => {
    if (tag) scope.setTag('f4s-error-type', tag);
    scope.setLevel('error');
    scope.setExtras(extras);
    return scope;
  });
  if (console) {
    console.error(error);
  }
};
