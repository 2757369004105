import axios, { type AxiosInstance } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

import { analyticsHeadersMiddleware } from '../middleware/analytics-headers';

let agent: AxiosInstance;
export function httpAgent(): AxiosInstance {
  if (agent) return agent;
  agent = axios.create({ timeout: 30000 });
  axiosBetterStacktrace(agent);
  agent.interceptors.request.use(analyticsHeadersMiddleware);
  return agent;
}
