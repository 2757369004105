import { DateTime } from 'luxon';

import AppText from '@f4s/i18n/en.json';
import { type MotivationPattern } from '@f4s/types';

import type { LanguageCode } from '@/constants/languages';
import { CARDS_DATE_FORMAT } from 'Constants';
import ItalianText from 'JSONs/it.json';
import ThaiText from 'JSONs/th.json';
import { capitalize } from 'Underscore';

import MOTIVATIONS from './motivations.json';
import { compare as compareText } from './text';

let languageCode: LanguageCode = 'en-us';

const IntlTexts = { 'it-it': ItalianText, 'th-th': ThaiText };

/** Motivations codes sorted by their alphabetical name */
// __PURE__ here tells Terser that this expression does not have any side effects
export const MOTIVATIONS_SORTED_BY_ALPHA = /* #__PURE__ */ [
  ...(MOTIVATIONS as MotivationPattern[]),
].sort((a, b) => compareText(AppText[a], AppText[b]));

// Searches backwards from match point; if it finds sentence boundary or
// start of string, it capitalizes the replacement
const replaceWithSentenceBoundaries =
  (vars: { [x: string]: string }) =>
  (_m: any, key: string, matchPos: number, original: string) => {
    let pos = matchPos - 1;
    while (pos >= 0) {
      if (pos === 0 || ['.', '!', '?'].includes(original.charAt(pos))) {
        return capitalize(vars[key]);
      }
      if (!/\s/.test(original.charAt(pos))) return vars[key];
      pos--;
    }
    return vars[key];
  };

/** Replaces ${}/{{}} template values in a given string  */
export const interpolate = (string: string, vars: { [x: string]: string }) => {
  const replaceFunc = replaceWithSentenceBoundaries(vars);
  return string
    .replaceAll(/\${(\w+)}/g, replaceFunc)
    .replaceAll(/{{(\w+)}}/g, replaceFunc);
};

/** Helper function for accessing AppText values with interpolation support */
export const t = (id: string, vars?: { [x: string]: string }) => {
  const localText = IntlTexts[languageCode]?.[id];
  const text = localText ?? AppText[id];

  if (text == null) throw new ReferenceError(`Invalid text id: ${id}`);
  return vars ? interpolate(text, vars) : text;
};

/** Formats an ISO date with the standard pattern */
export const formatDate = (time: string | Date | null) => {
  if (!time) return '';
  const date = time instanceof Date ? DateTime.fromJSDate(time) : DateTime.fromISO(time);
  return date.toFormat(CARDS_DATE_FORMAT);
};

export const formatUnixDate = (unixDate: number) =>
  unixDate ? DateTime.fromSeconds(unixDate).toFormat(CARDS_DATE_FORMAT) : '';

export const setLanguage = (code: LanguageCode) => {
  languageCode = code;
};

/** Formats a name with proper spacing in either full or first */
export const formatName = (
  format: 'full' | 'first',
  user: { firstName: string; lastName?: string },
) => {
  if (format === 'first' || !user.lastName) {
    return user.firstName;
  }

  return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '';
};

/** Formats a number with an ordinal suffix -`st,nd,rd,th` */
export const ordinalize = (number: number) =>
  // prettier-ignore
  String(number) + (
    Math.floor(number % 100 / 10) === 1 ? 'th'
    : number % 10 === 1 ? 'st'
    : number % 10 === 2 ? 'nd'
    : number % 10 === 3 ? 'rd'
    : 'th'
  );

export function daysUntil(isoStringDate: string) {
  const date = DateTime.fromISO(isoStringDate);
  const { days } = date.diff(DateTime.now(), 'days');
  if (days <= 1) return 'Tomorrow';
  if (days < 4) return `${days} days`;

  return date.toFormat('d MMMM');
}

export const capitalizeFirstLetterOfNames = (fullName: string) =>
  fullName.replaceAll(/(^\w)|(\s+\w)/g, (letter) => letter.toUpperCase());
