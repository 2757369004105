import { PureComponent } from 'react';
import NotificationSystem, { type Style } from 'react-notification-system';

import { merge } from 'Underscore';

const style: Style = {
  Containers: {
    DefaultStyle: {
      left: '0',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginLeft: '0',
      pointerEvents: 'none',
    },
  },

  NotificationItem: {
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      borderRadius: '50vw',
      padding: '24px 16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: '0',
      opacity: '0.8',
      height: 'auto',
      width: 'auto',
      textAlign: 'center',
      maxWidth: '500px',
      pointerEvents: 'auto',
    },

    success: {
      // Applied only to the success notification item
      backgroundColor: '#45c8a0',
      boxShadow: '#45c8a0 0px 0px 1px',
    },

    error: {
      backgroundColor: '#e44f67',
      boxShadow: '#e44f67 0px 0px 1px',
      color: '#FFFFFF',
    },

    warning: {
      backgroundColor: '#f7bf5c',
      boxShadow: '#f7bf5c 0px 0px 1px',
    },
  },
  Title: {
    DefaultStyle: {
      color: '#ffffff',
      fontSize: '16px',
      padding: '0px 32px 0 16px',
      margin: '0px',
    },
  },
  Action: {
    DefaultStyle: {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      borderRadius: '50vw',
      fontSize: '14px',
      outline: 'none',
    },
    error: {
      color: '#FFFFFF',
    },
  },
  Dismiss: {
    DefaultStyle: {
      top: '35%',
      right: '16px',
      backgroundColor: 'unset',
      width: '16px',
      height: '16px',
      fontSize: '24px',
    },
  },
};

// TODO: use react-notification-system types instead
/**
 * @typedef { import('react-notification-system').Notification } NotificationType
 * @type {{
 *  create: (
 *    message: NotificationType['message'],
 *    level: NotificationType['level'],
 *    opts?: NotificationType
 *  ) => void;
 * }}
 * */
export const Notification = {
  create: (message, level, opts?) => {
    console.warn(`Notification created when no Snackbar is mounted`, {
      message,
      level,
      opts,
    });
  },
};

const AUTO_DISMISS_MAPPING = {
  error: 10,
  success: 2.5,
  warning: 3.5,
  info: 2.5,
};

export class SnackBar extends PureComponent {
  notificationSystem: NotificationSystem | undefined | null;

  componentDidMount() {
    Notification.create = this.addNotification.bind(this);
  }

  addNotification(message, level, opts = {}) {
    if (!message) return;

    const autoDismiss = AUTO_DISMISS_MAPPING[level];
    const settings = merge({ title: message, level, autoDismiss }, opts);
    // @ts-expect-error FIXME: Object is possibly 'null'.
    this.notificationSystem.addNotification(settings);
  }

  render() {
    return (
      <NotificationSystem
        ref={(node) => {
          this.notificationSystem = node;
        }}
        style={style}
      />
    );
  }
}
