import { type SignUpAssessmentData } from '@/contexts/sign-up-with-assessment';
import * as API from 'APIs';

export const submitPendingMiniAssessment = async () => {
  const { areQuestionsAnswered, blockSlug, answers } = getPendingMiniAssessmentInfo();

  if (areQuestionsAnswered && blockSlug && answers) {
    try {
      await API.miniAssessment.createAndFinalizeMiniAssessment({
        blockSlug,
        answers,
      });
      removeLocalStorageForOnboarding();
    } catch (error) {
      console.warn(
        'Error saving mini assessment, perhaps a full assessment has already been completed',
        error,
      );
    }
  }
};

export const removeLocalStorageForOnboarding = () => {
  localStorage?.removeItem('signUpAssessmentData');
};

export const getPendingMiniAssessmentInfo = () => {
  const localStorageData = localStorage?.getItem('signUpAssessmentData');

  const signUpAssessmentData: SignUpAssessmentData = localStorageData
    ? JSON.parse(localStorageData)
    : null;

  const areQuestionsAnswered =
    signUpAssessmentData?.assessmentBlock?.areQuestionsAnswered ?? false;
  const blockSlug = signUpAssessmentData?.assessmentBlock?.blockSlug ?? false;
  const answers = signUpAssessmentData?.assessmentBlock?.answers ?? false;
  return { areQuestionsAnswered, blockSlug, answers };
};
