import { useEffect } from 'react';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';

export function useHistorySteps<Step extends string = string>(
  initial: Step,
  paramKey = 'step',
  replace = true,
): [Step, (newStep: Step) => void] {
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = useParams<{ [param: string]: Step }>();

  useEffect(() => {
    if (!params[paramKey]) {
      const newPath = generatePath(path, { ...params, [paramKey]: initial });
      history.replace({ pathname: newPath, search: window.location.search });
    }
  }, []);

  const step: Step = params[paramKey] || initial;

  const setStep = (newStep: Step) => {
    if (newStep === step) return;
    const newPath = generatePath(path, { ...params, [paramKey]: newStep });
    const method = replace ? 'replace' : 'push';
    history[method]({ pathname: newPath, search: window.location.search });
  };

  return [step, setStep];
}
