import { LoadClientEnv } from '@/env/load-client-env';

LoadClientEnv();

!(function () {
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console && console.error && console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e) {
        return function () {
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = `https://${window.SEGMENT_HOST}/analytics.js/v1/${key}/analytics.min.js`;
        const n = document.querySelectorAll('script')[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = window.SEGMENT_ID;
      analytics._cdn = `https://${window.SEGMENT_HOST}`;
      analytics.SNIPPET_VERSION = '4.13.2';
      analytics.load(window.SEGMENT_ID);
      analytics.page();
    }
})();
document.addEventListener('DOMContentLoaded', () => {
  const amplitudeHost = window.AMPLITUDE_HOST;
  if (!amplitudeHost) return;
  // eslint-disable-next-line no-undef
  analytics.ready(() => {
    const ampInstance = window.amplitude.getInstance();
    ampInstance.options.apiEndpoint = amplitudeHost;
  });
});
