import { LoadClientEnv } from '@/env/load-client-env';

LoadClientEnv();

window.dataLayer = window.dataLayer || [];
function gtag() {
  // eslint-disable-next-line no-undef
  dataLayer.push(arguments);
}
/**
 * The snippet in this file assumes it's run in global context, but we started
 * bundling this with Webpack which wraps this up in a module context.
 * So we need to manually make gtag global so it is accessible to other scripts
 */
window.gtag = gtag;
gtag({ 'gtm.start': Date.now(), 'event': 'gtm.js' });
gtag('js', new Date());
function addGtagScriptElem(gtagId) {
  if (!gtagId) return;
  const firstScript = document.querySelectorAll('script')[0];
  const newScript = document.createElement('script');
  newScript.async = true;

  newScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtagId}`;
  firstScript.parentNode.insertBefore(newScript, firstScript);
  window.dataLayer.push({ 'gtm.start': Date.now(), 'event': 'gtm.js' });
}
addGtagScriptElem(window.GTM_ID);
