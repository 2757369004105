import { useEffect, useRef, useState } from 'react';

/**
 * Hook to rate limit how frequently a value can change.
 * Successive values before the next delay will extend the duration.
 * Useful for avoiding excessive API calls on input fields.
 * */
export function useDebounce<T>(
  value: T,
  delay: number,
): [debouncedValue: T, isDebouncing: boolean] {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const didMount = useRef(false);

  useEffect(() => {
    // Avoid triggering on initial render
    if (!didMount.current) {
      didMount.current = true;
      return () => {};
    }

    setIsDebouncing(true);

    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, isDebouncing];
}
