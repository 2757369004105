import type { InternalAxiosRequestConfig } from 'axios';

export const analyticsHeadersMiddleware = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const url: string = (config.baseURL || '') + (config.url || '');
  if (
    url.startsWith('/') ||
    url.startsWith(`${window.location.protocol}//${window.location.host}`)
  ) {
    config.headers = config.headers ?? {};
    config.headers['x-f4s-request-url'] = window.location.href;
    config.headers['x-site'] = 'app';
  }
  return config;
};
