import qs from 'query-string';

import type { User } from '@f4s/types';

import * as segment from 'Lib/segment';
import { pickBy } from 'Underscore';

import { httpAgent as http } from './agents/http';

export const updatedProfileImage = (image: File) => {
  const formData = new FormData();
  formData.append('file', image);
  return http().post('/api/v1/users/uploadedProfileImage', formData);
};

export const updatePermissions = (data: {
  referralCode: string;
  status: string;
  action?: 'gave' | 'declined';
}) => http().post('/api/v1/users/permission', data);

export const getWellbeingScore = () =>
  http().get<{ scoreLife: any; scorePerson: any }>('/api/v2/user/wellbeing-score');

export const getSocialInfo = () => http().get('/api/v1/users/socials');

export const signOut = () => http().delete('/api/v2/auth/signout');

export const deleteConnection = (connectedUserId: number) =>
  http().delete(`/api/v3/users/connection/${connectedUserId}`);

export const switchContext = (data = {}) =>
  http().post('/api/v1/users/switch-context', data);

export const checkout = (data: { token: string; discount_code: string }) =>
  http().post<User>('/api/v2/payments/reassessment', data);

export const checkoutForOrgUser = (data: { sponsorBy: number }) =>
  http().post('/api/v2/payments/reassessment/sponsor', data);

export const checkoutForProUser = () => http().post('/api/v2/payments/reassessment/pro');

export const getGoals = () => http().get('/api/v2/user/goals?includes=subareas,goals');

export const setGoals = (data: { goals?: string[]; subareas?: number }) =>
  http().post('/api/v2/user/goals', data);

export const updateGoal = (
  goalId: number,
  data: Partial<{ makeCompleted: boolean; makeCoachingGoal: boolean; name: string }>,
) => http().put(`/api/v2/user/goals/${goalId}`, data);

export const deleteGoal = (goalId: number) =>
  http().delete(`/api/v2/user/goals/${goalId}`);

export const tokenSignIn = (data: { token: string; referralCode: string | null }) =>
  http().post('/auth/token-signin', data);

export const signupV2 = async (data: {
  emailAddress: string;
  firstName?: string;
  referralSource?: 'app';
  referralCode?: string;
  lastName?: string;
  password?: string;
  timezone?: string;
  skipNewQuestionnaire?: boolean;
  skipPasswordLink?: boolean;
  isOpenToMarketingEmails?: boolean;
  properties?: any;
}) => {
  const referralCode = data.referralCode ?? sessionStorage.getItem('referralCode');

  const utmProperties = pickBy(
    {
      utmCampaign: sessionStorage.getItem('utmCampaign'),
      utmMedium: sessionStorage.getItem('utmMedium'),
      utmSource: sessionStorage.getItem('utmSource'),
    },
    // Filter out nullable values undefined | null
    (value) => value != null,
  );

  const response = await http().post('/api/v2/auth/signup', {
    ...data,
    referralCode,
    properties: { ...utmProperties, ...data.properties },
  });

  segment.signedUpClientEvent();

  return response;
};

// TODO: Add support for referral code & UTM parameters on the iframe sign up
export const embeddedSignUp = async (data: {
  emailAddress: string;
  isOpenToMarketingEmails: boolean;
  properties?: any;
}) => {
  const response = await http().post('/api/v2/auth/signup', {
    ...data,
  });

  segment.signedUpClientEvent();

  return response;
};

export const signInV2 = (data: { emailAddress: string; password: string }) =>
  http().post('/api/v2/auth/signIn', data);

export const updateProfile = (params: Partial<User>) =>
  http().put('/api/v2/user', params);

export const getFullSignupSummary = () => http().get('/api/v2/user/summary?type=signup');

export const getJournals = (data: {
  page: number;
  limit?: number;
  programId?: string;
  motivationCode?: string;
  week?: number;
  session?: number;
}) => {
  const query = qs.stringify(data);
  return http().get<
    {
      // TODO: Move type to api schema and infer
      id: number;
      total: number;
      text: string;
      programName: string | null;
      programColourCode: string | null;
      createdAt: string;
      updatedAt: string;
    }[]
  >(`/api/v2/user/journals?${query}`);
};

export const getResources = (data: {
  page?: number;
  limit?: number;
  programId?: string;
  week?: number;
  session?: number;
}) => {
  const query = qs.stringify(data);
  return http().get(`/api/v2/user/resources?${query}`);
};

export const addJournal = (data: {}) => http().post('/api/v2/user/journals', data);

export const getIntegrationLoginLink = (integrationId: 'blisspot') =>
  http().get(`/api/v2/user/integration-login-link/${integrationId}`);

export const createAccessToken = (integrationId: 'blisspot') =>
  http().post(`/api/v2/user/access-token/${integrationId}`);

export const registerAffiliate = (
  referrer: string,
  campaign: string,
  currentAction: string,
) =>
  http().put(`/api/v2/user/affiliate`, {
    campaign,
    referrer,
    currentAction,
  });

export const setOnboardingCompleted = () =>
  http().post(`/api/v2/user/onboarding-completed`);

export const setProperty = (property: string, value: any) =>
  http().put('/api/v2/user/set-property', { property, value });

export const getBusinessEmail = () => http().get('/api/v2/user/business-email');

export const getPublicData = (token: string) =>
  http().get(`/api/v2/public/user/${token}`);

export const getReducedUserById = (userId: number) =>
  http().get(`/api/v3/public/users/${userId}`);

export const getEmailAddressAvailability = (emailAddress: string) =>
  http().get<boolean>(`/api/v3/public/users/email-availability/${emailAddress}`);

export const getCompanies = async (slug?: string) => {
  const baseUrl = '/api/v2/user/companies';
  const url = slug ? `${baseUrl}?slug=${slug}` : baseUrl;

  return http().get<{ companyName: string }[]>(url);
};
