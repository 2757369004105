import AppText from '@f4s/i18n/en.json';

import { Notification } from 'Components/view/snackbar';
import { isEmpty, redirectAsync } from 'Underscore';

const HTTP_MESSAGE_MAPPING = {
  413: `${AppText.notification_modal_avatar_error} ${AppText.upload_image_size_error}`,
  415: `${AppText.notification_modal_avatar_error} ${AppText.upload_image_type_error}`,
} as Record<number, string | undefined>;

type ErrorValue = {
  response: {
    data: NonNullable<unknown>;
    status?: number;
    code?: number;
    message?: string;
  };
  message?: string;
};

export async function errorHandler(
  error: unknown,
  notification = Notification,
  opts = {},
) {
  const { response, message: localMessage } = error as ErrorValue;

  /**
   * local error code: 100 ~ 110
   */
  if (isEmpty(response) && localMessage) {
    notification.create(localMessage, 'error', opts);
    return 100;
  }

  if (isEmpty(response)) {
    notification.create(
      'Uh... Error 500! Something went wrong at our end.',
      'error',
      opts,
    );
    return 500;
  }

  if (response.code === 500 || response.status === 500) {
    notification.create(
      'Uh... Error 500! Something went wrong at our end.',
      'error',
      opts,
    );
  }
  // To capture other un-auth errors that seem to now have code or message
  if (response.status === 401 && typeof response.data === 'string') {
    const { data } = response;
    notification.create(data, 'error', opts);
  } else {
    notification.create(
      HTTP_MESSAGE_MAPPING[response.status ?? -1] ?? response.message ?? localMessage,
      'error',
      opts,
    );
  }

  // TODO: Consider if we still need this
  // Causes issues when a random API response returns a 401, the whole page reloads
  if (response.code === 401) {
    await redirectAsync(`/sign-in?redir=${window.location.pathname}`);
  }

  return response.code;
}
