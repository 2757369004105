import clsx from 'clsx';

import styles from './loader.module.scss';

type LoaderProps = {
  size?: 'sm' | 'md' | 'lg';
  absolute?: boolean;
  flexStart?: boolean;
  transparent?: boolean;
  strokeColor?: string;
};

export const Loader = ({
  flexStart,
  size,
  transparent,
  absolute,
  strokeColor = 'stroke-white',
}: LoaderProps): React.ReactElement => (
  <div
    className={clsx(
      styles.container,
      flexStart && styles.flexStart,
      absolute && 'absolute',
    )}
  >
    <div
      className={clsx(
        styles.loader,
        size && styles[size],
        transparent && styles.transparent,
      )}
    >
      <svg width="56px" height="66px" viewBox="0 0 86 96" className={strokeColor}>
        <g stroke="inherit" fill="none" fillRule="evenodd" strokeLinecap="round">
          <g
            transform="translate(2.000000, 2.000000)"
            stroke="inherit"
            strokeWidth={4}
            className={styles.animate}
          >
            <path d="M13.5957031,8.0546875 C19.7614572,5.44594373 29.1776649,0.478066763 42.8129883,0.929199219 C50.2521958,1.17532965 58.19412,3.06447036 69.2890625,7.76054687" />
            <path d="M0.27421875,33.4921875 C10.4566404,18.3066405 49.1296844,-5.09413999 81.7523438,33.1703125" />
            <path d="M69.5242188,80.7949219 C59.4445316,80.7949219 45.1023436,81.9796867 40.6894531,58.4492188" />
            <path d="M30.4929687,90.2296875 C19.6105469,79.2691395 14.0562501,63.9457037 17.6242188,51.6035162 C19.4785369,45.1891264 26.0281673,38.8096002 33.0390624,36.6515625 C39.5323932,34.6528373 46.5399505,34.995341 51.4109364,37.5292968 C62.8703093,43.4906247 65.3476543,52.0648434 65.3476563,58.8695312" />
            <path d="M8.0265625,76.8386719 C6.86647931,73.4692318 1.66456422,53.6614928 9.26806655,40.4091791 C16.0045976,28.667929 30.4580078,23.132901 41.0683586,23.1391602 C55.4551973,23.147647 65.3705258,29.0001086 72.2382813,39.3574219 C79.6762696,50.5747074 79.5822265,62.9154312 73.8355469,67.4601562 C70.6856321,69.9512463 61.5447839,71.6983687 55.2374993,65.820311 C50.0378236,60.9744866 55.2374993,49.2880864 43.1757805,47.5219732 C41.1727302,47.2286805 38.310218,47.2354104 35.8115235,48.2763676 C33.4814176,49.2470907 31.4799746,51.1586571 30.4580071,53.4545903 C27.6039927,59.8663657 30.1347306,69.4579241 33.0087883,74.2075181 C38.2169126,82.8143307 48.7400473,89.4548476 55.2375,91.0835938" />
          </g>
        </g>
      </svg>
    </div>
  </div>
);
