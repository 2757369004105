import { type User } from '@f4s/types';

import { startCase } from 'Underscore';

import { logError } from './log-error';
import { Sentry } from './sentry';

const PAGE_TITLE_MAPPING = {
  plans: 'Upgrade Page',
};

let currentPageTitle = '';

// Call this when a user is logged in.
export const identify = (user: User) => {
  let attempts = 1;
  const linkUsers = () => {
    try {
      attempts += 1;
      const { id, firstName, lastName, emailAddress } = user;
      const name = [firstName, lastName].join(' ');
      const idString = id.toString();

      Sentry.setUser({ id: idString, email: emailAddress, username: name });

      window.analytics.identify(idString);

      window.clarity?.('identify', idString);

      // Test setting various custom tags to see what goes through
      window.clarity?.('set', 'customUserID', idString);
      window.clarity?.('set', 'userID', idString);
      window.clarity?.('set', 'f4sID', idString);
    } catch (error) {
      if (attempts <= 3) setTimeout(linkUsers, 1000);
      else {
        logError(error, { tag: 'analytics', userId: user?.id });
      }
    }
  };
  linkUsers();
};

export const logout = () => {
  Sentry.setUser(null);
  window.analytics.reset();
};

// Send Signed up event to Google Analytics
// Excludes sending Signed Up to segment tracking to avoid duplicated events
export const signedUpClientEvent = () => {
  try {
    window.gtag?.('event', 'Signed up');
    // TODO: replace or enhance with conversion API as well
    window.fbq?.('track', 'CompleteRegistration');
    window.fbq?.('track', 'SubmitApplication');
  } catch (error) {
    console.error('Tracking event failed - signedUpClientEvent');
    logError(error, { tag: 'analytics' });
  }
};

export const pageView = (page, opts = {}) => {
  currentPageTitle = startCase(PAGE_TITLE_MAPPING[page] || page);
  if ('id' in opts && opts.id && page === 'motivations') {
    currentPageTitle = 'Individual Motivation';
  }

  window.analytics.page(currentPageTitle, {
    ...opts,
  });
};
