import type { User } from '@f4s/types';

import { httpAgent as http } from './agents/http';

export type AppSettings = {
  user?: User;
  // Settings
  chargebee: {
    key: string;
    site: string;
  };
  intercomAppId: string;
};

export const get = () => http().get<AppSettings>('/api/v1/appSettings');
