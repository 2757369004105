import { logError } from '@/lib/log-error';

export function BrowserStorage(type = 'local'): Storage {
  try {
    const storage = type === 'local' ? window.localStorage : window.sessionStorage;
    storage.setItem('test', 'test');
    storage.removeItem('test');
    return storage;
  } catch (error) {
    if (error instanceof DOMException && error.code === error.QUOTA_EXCEEDED_ERR) {
      logError(error);
    }
  }

  return {} as Storage;
}

export function getStorageItem({ storage, key }: { storage: Storage; key: string }) {
  const localStorageString = storage.getItem(key);

  if (localStorageString !== null) {
    // Check if value is malformed - remove to avoid future errors
    try {
      return JSON.parse(localStorageString);
    } catch {
      storage.removeItem(key);
    }
  }

  // TODO: Validate return types from sessionStorage with zod
  // FIXME: Avoid returning empty object to suppress destructuring errors
  return {};
}
