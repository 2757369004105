import { type AnalyticsSchema, type EventName } from '@f4s/types/analytics';

import { logError } from '@/lib/log-error';

import { httpAgent as http } from './agents/http';

export const sendEvent = <K extends EventName>(
  eventName: K,
  payload: AnalyticsSchema<K>,
) => {
  http()
    .post(`/api/v3/public/analytics`, { eventName, payload })
    .catch((error) => logError(error));
};
