/**
 * Custom polyfill for Array.at - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at
 * Current browser support: https://caniuse.com/mdn-javascript_builtins_array_at
 * Also includes support for String.at
 */
export function atPolyfill<T>(this: ArrayLike<T>, pos: number): T | undefined {
  const index = Math.trunc(pos);
  return index < 0 ? this[index + this.length] : this[index];
}

if (!Array.prototype.at) {
  Object.defineProperty(Array.prototype, 'at', {
    value: atPolyfill,
    enumerable: false,
    writable: true,
    configurable: true,
  });
}

if (!String.prototype.at) {
  Object.defineProperty(String.prototype, 'at', {
    value: atPolyfill,
    enumerable: false,
    writable: true,
    configurable: true,
  });
}
