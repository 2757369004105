import React, { useContext, type Context } from 'react';
import { useLocation } from 'react-router-dom';

import { uniq } from '@/lib/underscore';

import type { ContextValue, InitialValue, NonValue, URLParamOptions } from './types';

const createUninitializedError = (contextName: string) =>
  new Error(
    `Accessing uninitialized state - ${contextName}. ${
      process.env.NODE_ENV === 'development'
        ? 'Ensure context is registered in context-stack.tsx'
        : ''
    }`,
  );

export const contextValueFactory = <T>(
  contextName: string,
): {
  initialState: InitialValue;
  nonValueState: NonValue;
  wrapState: (contextValue: T) => ContextValue<T>;
} => {
  const initialState: InitialValue = {
    unwrap: () => {
      throw createUninitializedError(contextName);
    },
    ready: false,
    loading: true,
    value: undefined,
  };

  const nonValueState: NonValue = {
    unwrap: () => {
      throw createUninitializedError(contextName);
    },
    ready: false,
    loading: false,
    value: undefined,
  };

  const wrapState = (contextValue: T): ContextValue<T> => ({
    unwrap: () => contextValue,
    ready: true,
    loading: false,
    value: contextValue,
  });

  return { nonValueState, initialState, wrapState };
};

/**
 * React Hook to access context value from context.
 * Also performs `unwrap()` on the value which throws an error if the
 * context hasn't yet been initialized.
 *
 * For accessing pre-initialized values use `React.useContext`.
 * @param context
 * @returns unwrapped context value
 */
export const useAppContext = <T>(context: Context<ContextValue<T>>): T => {
  const wrappedContext = useContext(context);
  return wrappedContext.unwrap();
};

/** Render Context Wrappers with the first context closest to the root */
export const renderContextStack = (
  contexts: React.FunctionComponent<{ children?: React.ReactNode }>[],
  children: React.ReactNode,
) => {
  let stack = React.createElement(React.Fragment, {}, children);
  contexts.reverse().forEach((contextWrapper) => {
    stack = React.createElement(contextWrapper, {}, stack);
  });
  return stack;
};

export const useComparisonLocationQuery = (key: URLParamOptions) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return uniq(searchParams.getAll(key)).map(Number).filter(Boolean);
};
