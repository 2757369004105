import { ButtonWithChildren } from './button-with-children';
import { type ButtonContentProps, type ButtonProps } from './types';

const ButtonContent = ({ text, frontIcon, trailingIcon }: ButtonContentProps) => (
  <>
    {frontIcon}
    {text}
    {trailingIcon}
  </>
);

export const Button = ({
  variant,
  color = 'brand',
  size = 'base',
  isSubmitBtn = false,
  disabled,
  isFullWidth = false,
  isLoading = false,
  isIconOnly = false,
  isSolidIcon = false,
  isColumnDirection = false,
  onClick,
  text,
  frontIcon,
  trailingIcon,
  isThemed = false,
}: ButtonProps): React.ReactElement => (
  <ButtonWithChildren
    variant={variant}
    color={color}
    size={size}
    isSubmitBtn={isSubmitBtn}
    disabled={disabled}
    isFullWidth={isFullWidth}
    isLoading={isLoading}
    onClick={onClick}
    isIconOnly={isIconOnly}
    isSolidIcon={isSolidIcon}
    isColumnDirection={isColumnDirection}
    isThemed={isThemed}
  >
    <ButtonContent text={text} frontIcon={frontIcon} trailingIcon={trailingIcon} />
  </ButtonWithChildren>
);
