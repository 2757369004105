import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { type User } from '@f4s/types';

import { removeLocalStorageForOnboarding } from '@/pages/sign-in/mini-assessment';
import * as API from 'APIs';
import * as segment from 'Lib/segment';
import { BrowserStorage } from 'Lib/utils/local-storage';

const storage = BrowserStorage('local');

export const useFetchAppSettings = () =>
  useQuery(['appSettings.get'], () =>
    API.appSettings.get().then((response) => response.data),
  );

/**
 * Use this hook for components that may or may not require the user to be logged in.
 */
export const useMaybeUser = () => {
  const { data, isLoading } = useFetchAppSettings();
  const queryClient = useQueryClient();
  const user = data?.user;
  const isLoggedIn = Boolean(user);

  const signOut = useCallback(
    async (redir = '/sign-in') => {
      if (isLoggedIn) {
        // Invalidate sessions across browser tabs
        storage.setItem('f4s-logout-event', `logout ${new Date().toISOString()}`);

        await API.user.signOut();
        segment.logout();
        // Prevent wrong sign up data is used when switching accounts
        removeLocalStorageForOnboarding();
      }
      window.location.href = redir;
    },
    [isLoggedIn],
  );

  const updateProfile = useCallback(
    (newData: Partial<User>) => {
      queryClient.setQueryData<API.appSettings.AppSettings>(
        ['appSettings.get'],
        (previousAppSettings) => {
          const newUser = { ...previousAppSettings?.user, ...newData };

          return {
            ...previousAppSettings,
            user: newUser,
          } as API.appSettings.AppSettings;
        },
      );
      queryClient.invalidateQueries({
        queryKey: ['user', newData.id, 'business-email'],
      });
    },
    [queryClient],
  );

  return { user, isLoggedIn, isLoading, signOut, updateProfile };
};

/**
 * Use this hook for when you are sure the user object exists
 */
export const useUser = () => {
  const maybeUser = useMaybeUser();

  if (!maybeUser.user) {
    throw new Error('User is not defined');
  }

  return { ...maybeUser, user: maybeUser.user };
};

// TODO: Separate org data from app settings - so it doesn't all need to be invalidated
export const useInvalidateAppSettings = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(['appSettings.get']);
};
